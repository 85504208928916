<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Manage_Right_Message_Notice_AddEditNotice'" @click="addNotice" >新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Manage_Right_Message_Notice_AddEditNotice'" @click="saveNotice" >保存</el-button> </div>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_noticeForm" :rules="noditNoticeValidate">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>公告标题：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="NoticeTItle">
                            <el-input v-model="dataSource.NoticeTItle" :maxlength="30" :minlength="2" placeholder="公告标题"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                    </el-col>
                    <el-col :span="7">
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">公告状态：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="NoticeStatus">
                            <el-select v-model="dataSource.NoticeStatus" :maxlength="200">
                                <el-option v-for="item in NoticeStatuss"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                    </el-col>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>过期时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="ExpirationTime">
                                
                                <el-date-picker v-model="dataSource.ExpirationTime"
                                                type="datetime"
                                                placeholder="过期时间">
                                </el-date-picker>
                                
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>公告内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="NoticeContent">
                            <el-input type="textarea" v-model="dataSource.NoticeContent" :maxlength="500"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                    
                    
            </el-form>
        </div>
    </div>
    
</template>

<script>
    export default {
        data() {
            var validateNoticeStatus = (rule, value, callback) => {
                if (value == undefined || value === '') return callback(new Error('状态不能为空'));
                callback();
            };
            var validateTime = (rule, value, callback) => {
                if (!value) return callback(new Error('请输入过期时间'));
                callback();
            };
            return {
                NoticeStatuss: [
                    { label: '启用', value: 100 },
                    { label: '停用', value: 200 }
                ],
                noditNoticeValidate: {
                    NoticeStatus: [
                        { validator: validateNoticeStatus, trigger: 'blur' }
                    ],
                    NoticeTItle: [
                        { required: true, message: '请输入公告标题', trigger: 'blur' }
                    ],
                    ExpirationTime: [
                        { validator: validateTime, trigger: 'blur' }
                    ],
                    
                    NoticeContent: [
                        { required: true, message: '请输入公告内容', trigger: 'blur' }
                    ],
                    
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource: {}
        },
        methods: {
            addNotice() {
                this.Event.$emit("onAddNotice");
            },
            saveNotice() {
                this.$refs["_noticeForm"].validate((valid) => {
                    if (valid) {
                        var routeName = this.dataSource.NoticeID === this.Utils.emptyGuid ? "add" : "edit";
                        this.$ajax.send("omsapi/msgnotice/" + routeName, "post", this.dataSource, (data) => {
                            this.dataSource.NoticeID === this.Utils.emptyGuid && (this.dataSource.NoticeID = data.Result);
                            this.$parent.syncDataSource();
                            this.Event.$emit("reloadNoticePageList", data.Result);
                            this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>
